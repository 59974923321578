var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loading,"show-expand":"","expanded":_vm.expanded,"options":_vm.options,"server-items-length":_vm.total,"single-expand":"","items":_vm.webhooks,"item-key":"uuid","footer-props":_vm.footerProps},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prettyDate(item.createdAt))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-3",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"my-1"},[_c('v-col',{staticClass:"d-flex flex-column"},[_c('label',{attrs:{"for":""}},[_vm._v("Event:")]),_c('span',[_vm._v(_vm._s(item.event))])]),_c('v-col',{staticClass:"d-flex flex-column"},[_c('label',{attrs:{"for":""}},[_vm._v("Created At:")]),_c('span',[_vm._v(_vm._s(_vm.prettyDate(item.createdAt)))])])],1),_c('v-divider',{staticClass:"my-1"}),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column"},[_c('label',{attrs:{"for":""}},[_vm._v("Time stamp:")]),_c('span',[_vm._v(_vm._s(_vm.prettyDate(item.timestamp)))])]),_c('v-col',{staticClass:"d-flex flex-column"},[_c('label',{attrs:{"for":""}},[_vm._v("Updated At:")]),_c('span',[_vm._v(_vm._s(_vm.prettyDate(item.updatedAt)))])])],1),_c('v-divider',{staticClass:"my-1"}),_c('div',{staticClass:"\n              text-center\n              font-weight-bold\n              text-decoration-underline\n              mb-2\n            "},[_vm._v(" Headers ")]),_c('v-row',{attrs:{"no-gutters":""}},[(item.headers)?_vm._l((item.headers),function(value,key,i){return _c('v-col',{key:i,staticClass:"pa-1",attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(key)+":")]),_c('span',[_vm._v(_vm._s(value))])])])}):_vm._e()],2),_c('v-divider',{staticClass:"my-1"}),_c('div',{staticClass:"\n              text-center\n              font-weight-bold\n              text-decoration-underline\n              mb-2\n            "},[_vm._v(" Payload ")]),_c('v-row',[(item.payload)?_vm._l((item.payload),function(value,key,i){return _c('v-col',{key:i,staticClass:"pa-1",attrs:{"cols":"12","sm":key == 'object' ? 8 : 3}},[(key == 'object')?[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((value),function(v,k,ind){return _c('v-col',{key:ind,staticClass:"pa-1",attrs:{"cols":"12","sm":"3"}},[(
                          k == 'callee' ||
                          k == 'caller' ||
                          k == 'forwarded_by' ||
                          k == 'owner'
                        )?[_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(k)+":")]),_c('div',{staticClass:"d-flex flex-column"},_vm._l((v),function(val,ke,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex flex-column"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(ke)+":")]),_c('span',[_vm._v(_vm._s(val))])])])}),0)]:(k == 'call_logs' || k == 'recordings')?[_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(k)+":")]),_c('div',{staticClass:"d-flex flex-column"},_vm._l((v),function(val,ke,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex"},[_c('v-row',_vm._l((val),function(valu,kk,ii){return _c('v-col',{key:ii,attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(kk)+":")]),_c('span',[_vm._v(_vm._s(valu))])])])}),1)],1),_c('v-divider')],1)}),0)]:[_c('div',{staticClass:"d-flex flex-column"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(k)+":")]),_c('span',[_vm._v(_vm._s(v))])])]],2)}),1)]:[_c('div',{staticClass:"d-flex flex-column"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(key)+":")]),_c('span',[_vm._v(_vm._s(value))])])]],2)}):_vm._e()],2)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }